<template>
  <div class="forgot_pass">
    <div>
      <div class="d-flex mb-5 back" @click="$router.push('/admin')">
        <i class="fad fa-angle-double-left mr-2"></i>
        <h6>{{ $t("admin.back") }}</h6>
      </div>
      <form action="" @submit.prevent="reset">
        <div class="loginLogo">
          <img src="../../../assets/img/logo.png" alt="" />
        </div>
        <div class="title">
          <h5>{{ $t("reset_pass.enter_email") }}</h5>
        </div>
        <div class="input">
          <input type="text" :placeholder="$t('admin.email')" v-model="email" />
        </div>
        <div class="button">
          <button type="submit">
            {{ $t("admin.send") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    reset() {
      axios
        .post(`${env.host}/forgot-password`, {
          email: this.email,
        })
        .then((res) => {
          console.log(res);
          if (res.data.success === false) {
            Swal.fire({
              icon: "error",
              text: this.$t("reset_pass.wrong_mail"),
            });
          } else {
            Swal.fire({
              text: this.$t("reset_pass.get_pass"),
            });
            this.$router.push("/admin");
          }
        });
    },
  },
};
</script>

<style scoped>
h5 {
  font-size: 16px;
  align-items: center;
  text-align: center;
}
.title {
  margin: auto;
  justify-content: center;
  align-items: center;
}
.loginLogo img {
  width: 250px;
  display: flex;
  margin: auto;
}
.back {
  cursor: pointer;
  transition: 0.3s;
}
.back:hover {
  margin-left: 5px;
}
.forgot_pass {
  margin-top: 0 !important;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  margin: auto;
  padding: 40px;
}
.forgot_pass form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forgot_pass input {
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border: 1px solid #dadada;
  outline: none;
  padding-left: 20px;
}
.forgot_pass input:focus {
  border: 1px solid #7e5493;
  transition: 0.3s;
}
.forgot_pass button {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>